/* html, body {
  font-size: 14px;
} */
* {
  font-family: 'Helvetica Neue', sans-serif;
}

[class*="sidebar-dark-"] .sidebar a {
  color: #e7e7e7;
}

.sidebar-collapse .hide-when-collapse {
  display: none;
}

[class*="sidebar-dark-"] .nav-treeview>.nav-item>.nav-link {
  color: #e7e7e7;
}

.brand-link .brand-image {
  float: none;
}

.table>:not(caption)>*>* {
  font-size: 14px;
  padding: 0.75rem;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-size: 14px;
  font-weight: 500;
}

.react-responsive-modal-modal {
  margin: 70px 1.2rem 1.2rem 1.2rem;
}

.main-header {
  z-index: 995;
}

.card-primary.card-outline-tabs>.card-header a:hover {
  border-top: 3px solid #F46C03;
}

.card-primary.card-outline-tabs>.card-header a.active {
  border-top: 3px solid #F46C03;
}

a:hover {
  color: #F46C03;
}

a {
  color: #F46C03;
  text-decoration: underline;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #F46C03;
  color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #F46C03;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #F46C03;
  border-color: #F46C03;
}

.page-link:hover {
  z-index: 2;
  color: #F46C03;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.risk {
  border: 2px solid #dd1313;

  border-radius: 30px;
  background-color: #f7eeee;
}

.btn-primary:hover {
  color: #fff;
  background-color: #F46C03;
  border-color: #F46C03;
  ;
}

.btn-primary {
  color: #fff;
  background-color: #F46C03;
  border-color: #F46C03;
  ;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #F46C03;
}

.nav-pills .nav-link:not(.active):hover {
  color: #F46C03;
}

.capitalize-first {
  text-transform: capitalize;
}

.btn-success:hover {
  color: #fff;
  background-color: #f5791a;
  border-color: #f5791a;
}

.btn-success {
  color: #fff;
  background-color: #F46C03;
  border-color: #F46C03;
}

.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #F46C03;
  border-color: #F46C03;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #F46C03;
  border-color: #F46C03;
  box-shadow: 0 0 0 .25rem rgba(253, 129, 12, 0.5);
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(253, 129, 12, 0.5);
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #f5791a;
  border-color: #f5791a;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 0rem !important;
  position: relative;
  overflow-y: auto;
  min-width: 400px;
}

.page-link {
  position: relative;
  display: block;
  color: #f5791a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}